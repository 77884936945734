<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" class="mb-4 pa-0">
        <v-card flat>
          <v-img
            :src="require('@/assets/kingston_locations.png')"
            class="align-end"
            aspect-ratio="2.8"
            position="center 10%"
            max-height="300"
          >
            <v-slide-x-transition>
              <v-card-title class="mb-2 pl-0" v-if="ready">
                <span
                  class="pa-4 white--text"
                  style="
                    background-color: rgba(0, 0, 0, 0.8);
                    border-bottom: #c8102e 10px solid !important;
                    border-radius: 0 5px 5px 0;
                    backdrop-filter: blur(6px);
                  "
                >
                  {{ $t("welcome.welcome") }}
                  <span v-if="$vuetify.breakpoint.smAndUp">
                    - {{ userInfo.name }}
                  </span>
                </span>
              </v-card-title>
            </v-slide-x-transition>
          </v-img>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="text-center mt-0">
      <v-col cols="12">
        <v-btn v-if="!authState.isAuthenticated" @click="login" color="primary">
          {{ t("welcome.login") }}
        </v-btn>
        <div v-else>
          <v-row dense justify="center" class="">
            <v-col class="d-flex" style="min-width:200px" v-if="!regionCheck('travel')">
              <v-card color="primary" to="new" width="100%">
                <v-card-text>
                  <v-icon size="60">mdi-airplane</v-icon>
                </v-card-text>
                <v-card-text>
                  <h2 class="white--text">{{ $t("travelReq.newTravelReq") }}</h2>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="d-flex" style="min-width:200px" v-if="!regionCheck('transportation')">
              <v-card color="primary" to="new-transportation" width="100%">
                <v-card-text>
                  <v-icon size="60">mdi-taxi</v-icon>
                </v-card-text>
                <v-card-text>
                  <h2 class="white--text">{{ $t("transportation.newTransportation") }}</h2>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="d-flex" style="min-width:300px">
              <v-card color="primary" to="new-fv-accommodation" width="100%">
                <v-card-text>
                  <v-icon size="60">mdi-bed</v-icon>
                </v-card-text>
                <v-card-text>
                  <h2 class="white--text">{{ $t("fvAccommodation.newAccommodation") }}</h2>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- <v-btn class="ma-2" color="primary" to="new">
            <v-icon left>mdi-airplane</v-icon>
            {{ $t("travelReq.newTravelReq") }}
          </v-btn>
          <v-btn class="ma-2" color="primary" to="new-transportation">
            <v-icon left>mdi-taxi</v-icon>
            {{ $t("transportation.newTransportation") }}
          </v-btn>
          <v-btn class="ma-2" color="primary" to="new-fv-accommodation">
            <v-icon left>mdi-bed</v-icon>
            {{ $t("fvAccommodation.newAccommodation") }}
          </v-btn> -->
        </div>
      </v-col>
    </v-row>

    <travel-reqs-authorize/>

    <transportation-reqs-authorize/>

    <FVAccommodationReqsAuthorize/>

    <transportation-reqs-approve/>

    <FVAccommodationReqsApprove/>

    <travel-reqs-process/>

    <transportation-reqs-process/>

    <FVAccommodationReqsProcess/>
    
    <travel-reqs/>

    <transportation-reqs/>

    <FVAccommodationReqs/>
  </v-container>
</template>

<script>
import { mapState } from "vuex"
import StatusChip from "@/components/StatusChip"

import TravelReqs from "@/components/welcome/TravelReqs"
import TravelReqsAuthorize from "@/components/welcome/TravelReqsAuthorize"
import TravelReqsProcess from "@/components/welcome/TravelReqsProcess"

import TransportationReqs from "@/components/welcome/TransportationReqs"
import TransportationReqsAuthorize from "@/components/welcome/TransportationReqsAuthorize"
import TransportationReqsApprove from "@/components/welcome/TransportationReqsApprove"
import TransportationReqsProcess from "@/components/welcome/TransportationReqsProcess"

import FVAccommodationReqs from "@/components/welcome/FVAccommodationReqs"
import FVAccommodationReqsAuthorize from "@/components/welcome/FVAccommodationReqsAuthorize"
import FVAccommodationReqsApprove from "@/components/welcome/FVAccommodationReqsApprove"
import FVAccommodationReqsProcess from "@/components/welcome/FVAccommodationReqsProcess"

export default {
  name: "Welcome",

  data: () => ({
    ready: false,
  }),
  components: {
    StatusChip,
    TravelReqs,
    TravelReqsAuthorize,
    TravelReqsProcess,
    TransportationReqs,
    TransportationReqsAuthorize,
    TransportationReqsApprove,
    TransportationReqsProcess,
    FVAccommodationReqs,
    FVAccommodationReqsAuthorize,
    FVAccommodationReqsApprove,
    FVAccommodationReqsProcess
  },
  computed: {
    ...mapState(["userInfo", "region"]),
  },
  methods: {
    async login() {
      await this.$auth.signInWithRedirect({ originalUri: "/" })
    },
    regionCheck(category) {
      var travelRegions = ["FV", "UK", "IE"]
      var transportRegions = ["FV", "UK", "IE"]
      switch(category) {
        case "travel":
          return !travelRegions.includes(this.region)
        case "transportation":
          return !transportRegions.includes(this.region)
      }
    }
  },
  async mounted() {
    setTimeout(() => {
      this.ready = true
    }, 300)
  }
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
