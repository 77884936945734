<template>
  <div>
    <v-snackbar
      v-model="snackbar.open"
      :timeout="snackbar.timeout"
      top
      right
      :color="snackbar.color"
      @click.native="goTo()"
    >
      {{ snackbar.message }}
      <!-- <v-progress-linear v-model="percentage" color="white"> </v-progress-linear> -->
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar.open = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import * as signalR from "@microsoft/signalr"
import { mapState } from "vuex"
export default {
  data() {
    return {
      snackbar: {
        open: false,
        timeout: -1,
        color: "warning",
        message: ""
      }
    }
  },
  computed: {
    ...mapState(["syncConnection", "userInfo"])
  },
  async created() {
    this.syncConnection.on("UpdateTravelReqsAuthorize", async () => {
      if (this.has([this.permissions.manager])) {
        await this.$store.dispatch("travel/getAuthorizeCount")
        // this.setMessage("You have new travel requests to authorize.", "/authorize/travel")
      }
    })
    this.syncConnection.on("UpdateTravelReqsProcess", async () => {
      if (this.has([this.permissions.travelCoordinator])) {
        await this.$store.dispatch("travel/getProcessCount")
        // this.setMessage("You have new travel requests to process.", "/search/travel")
      }
    })
    this.syncConnection.on("UpdatePermissions", async () => {
      await this.$store.dispatch("getPermissions")
    })
    this.syncConnection.on("ReceiveMessage", async (message) => {
      this.setMessage(message)
      console.log(message)
    })
    this.syncConnection.on("ReceivePrivateMessage", async (user, message) => {
      this.setMessage(`${user}: ${message}`)
      console.log(`${user}: ${message}`)
    })

    this.syncConnection.on("UpdateTravelReq", (id) => {
      this.setMessage(`Travel request #${id} has been updated. Refresh to see changes.`)
      console.log(`Travel request #${id} has been updated. Refresh to see changes.`)
    })
    this.syncConnection.on("UpdateTransportationReq", (id) => {
      this.setMessage(`Transportation Batch #${id} has been updated. Refresh to see changes.`)
      console.log(`Transportation Batch #${id} has been updated. Refresh to see changes.`)
    })
    this.syncConnection.on("UpdateFVAccommodationReq", (id) => {
      this.setMessage(`FV Accommodation #${id} has been updated. Refresh to see changes.`)
      console.log(`FV Accommodation #${id} has been updated. Refresh to see changes.`)
    })
    await this.start()
  },
  methods: {
    setMessage(message, url, color) {
      this.snackbar.message = message
      this.snackbar.url = url
      this.snackbar.color = color || "warning"
      this.snackbar.open = true
    },
    goTo() {
      this.snackbar.open = false
      if (this.snackbar.url)
        this.$router.push(this.snackbar.url)
    },
    async start() {
      if (
        this.syncConnection.state == signalR.HubConnectionState.Disconnected
      ) {
        var _this = this
        this.syncConnection
          .start()
          .then(function () {
            // _this.syncConnection
            //   .invoke("SendMessage", "Hello World")
            //   .catch(function (err) {
            //     return console.error(err.toString())
            //   })
            // setTimeout(() => {
            //   _this.syncConnection
            //     .invoke("SendPrivateMessage", "Darryl_Wu@Kingston.com", "Hello World")
            //     .catch(function (err) {
            //       return console.error(err.toString())
            //     })
            // }, 1000)
          })
          .catch(async (err) => {
            return console.error(err.toString())
          })
        // var _this = this
        // this.syncConnection
        //   .start()
        //   .then(function () {
        //     _this.syncConnection
        //       .invoke("GroupMe", _this.userInfo.employeeID)
        //       // .invoke("GroupMe", _this.userInfo.email)
        //       .catch(function (err) {
        //         return console.log(err)
        //       })

        //     // _this.joinNotifications()
        //   })
        //   .catch(async function (err) {
        //     await _this.retryConnection()
        //     return console.log(err)
        //   })
      }
    }
  }
}
</script>
